@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

@layer components {
    .btn {
        @apply px-16 py-4 rounded-3xl font-medium inline-flex items-center justify-center tracking-tight;
    }

    .btn-primary {
        @apply text-white;
        background-color: #3AAFA9;
        box-shadow: 0 0 20px rgba(45, 133, 126, 0.5);
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .btn-primary:hover {
        @apply bg-gray-900;
        box-shadow: 0 0 20px rgba(45, 133, 126, 0.5);
        background-color: #3AAFA9;
        transform: scale(1.05);
    }

    .btn-text {
        @apply text-black text-lg bg-transparent;
    }

    /* styles.css */
    .btn-call-to-action {
        @apply px-8 py-4 text-xl font-bold text-white rounded-3xl flex items-center justify-center;
        background-color: #3AAFA9; /* Dark Blue for contrast */
        box-shadow: 0 0 10px rgba(45, 133, 126, 0.5);
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        text-align: center; /* Center align text */
        white-space: normal; /* Allow text to wrap */
      }
      
      .btn-call-to-action:hover {
        @apply bg-teal-500;
        box-shadow: 0 0 20px rgba(45, 133, 126, 0.5);
        transform: scale(1.05);
      }
          

    .logo-ticker {
        @apply h-8 w-auto;
    }

    .tag {
        @apply text-sm inline-flex border border-[#222]/10 px-3 py-1 rounded-lg tracking-tight;
    }

    .text-highlight {
        @apply text-[#3AAFA9] font-bold border-b-2 border-[#3AAFA9]; /* Example highlight color */
        transition: color 1s ease, border-bottom 1s ease; /* Smooth transition */
    }
}
